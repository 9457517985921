/* src/components/ScrollToTop/ScrollToTop.css */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #7b8e90; /* Customize the background color */
  color: #fff; /* Customize the icon color */
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.scroll-to-top:hover {
  background-color: #333; /* Customize hover color */
}
