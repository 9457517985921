/* src/components/ScrollToTop/ScrollToTop.css */
.DiscoverSolutin {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: rgba(
    255,
    255,
    255,
    0.288
  ); /* Customize the background color */
  color: black; /* Customize the icon color */
  border-radius: 5%;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.DiscoverSolutin:hover {
  background-color: grey; /* Customize hover color */
  color: white;
}
