.section-who-table-1 {
  width: 100%;
  /* border: 3px solid red; */
  height: 100px;
  font-family: "QuickSand";
  border-collapse: collapse; /* Collapses the borders */
  top: 0%;
  text-align: left;
}
.section-who-table-1 {
  width: 100%;
  border-collapse: collapse;
}

.section-who-box {
  margin: 1rem;
  align-content: flex-start;
  position: relative;
  width: 50%;
}
.section-who-box h1 {
  /* margin-bottom: 0px; */
  font-size: 25px;
}

.arrow-cell {
  align-items: center;
  /* border: 1px solid red; */
  padding: 1rem;
  transform: rotateX(180deg); /* Vertically flip the arrow */
}

.section-who-table {
  /* background-color: red; */
  /* border: 11px solid black !important; */
  padding: 0px 0px;
  display: flex; /* Use flexbox for centering */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center children vertically */
  align-items: start; /* Center children horizontally */

  align-self: center;
}

.section-who-main {
  background: url("../../assets/Who_bg_img.png");
  background-repeat: fill;
  background-size: contain;
  position: relative; /* min-height: 500px; Adjust as needed */
  padding-top: 20px;
}

.section-who h1,
h3 {
  font-weight: 600 !important;
}
.section-who-table {
  width: 100%;
}
body {
  font-family: "QuickSand";
}

.section-who-title-cell h1 {
  margin-bottom: 20px;
  font-size: 25px;
}
.section-who-description-cell p {
  font-size: 20px;
}

.four-columns td {
  border-left: 1px solid #d5d5d5;
}

.four-columns {
  padding: 1rem !important;
}

.section-who-btn-cell {
  height: 230px;
  padding: 10px 20px !important;
}

.section-who-btn-cell h1 {
  color: #878688;
  font-size: 40px;
  margin: 0px;
  text-transform: uppercase;
}

.section-who-btn-cell h3 {
  color: black;
  font-size: 18px;
  text-transform: uppercase;
}
.section-who-btn-cell h6 {
  color: black;
  font-size: 15px;
  margin: 0px;
  font-weight: lighter;
}

.section-who-btn-cell td {
  font-size: 19.6px;
  width: 100% !important;
}

.section-who-footer-1 {
  border: 1px solid white;
  border-radius: 10px;
  background-color: #878688;
  max-height: 10px;
  margin: 0px;
  padding: 0px;
}

.section-who-footer-1 h1 {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.section-who-footer-2 {
  border: 1px solid white;
}
.section-who-footer-btn {
  width: 20%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: black;
  border: 1px solid white;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}
.section-who-footer-btn:hover {
  background-color: #878688;
}

.section-who-footer-btn-1 {
  background-color: transparent; /* Primary color */
}

.section-who-footer-btn-2 {
  color: black; /* Secondary color */
  float: right;
}

/* <-----------------------Section what slide 2--------------------------------------> */

.section-who-2 {
  background: url("../../assets/Who_bg_img.png");
  background-color: red;

  /* border: 1px solid black !important; */
  padding: 0px 60px;
  /* Position and center the image to scale nicely on all screens */
  background-position: bottom;
  background-repeat: no-repeat;
  /* background-size: cover; */
  position: relative; /* min-height: 500px; Adjust as needed */
}

.section-who-four-columns td {
  border-left: 1px solid none;
}
.slider-container {
  overflow: hidden; /* Hide scrollbars */
  position: relative;
}

.slick-slider {
  overflow: hidden; /* Hide scrollbars */
}

.slide-item {
  transition: transform 0.5s ease-in-out; /* Smooth transition for slides */
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-slide img {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease-in-out;
}

/* Media queries for mobile screens */
@media (max-width: 768px) {
  .section-who {
    padding: 20px;
    /* border: 1px solid red; */
    /* max-width: 100%; */
    overflow: hidden; /* or hidden, scroll, etc. */
    max-width: 100%;
    height: max-content;
  }
  .section-who-main {
    background: url("../../assets/Who_bg_img.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    position: relative; /* min-height: 500px; Adjust as needed */
    border: 1px solid black;
  }
  .four-columns {
    display: inline-grid;
  }
}
