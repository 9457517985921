.section-how-table {
  width: 100%;
  /* border: 3px solid red; */
  height: 100%;
  font-family: "QuickSand";
  border-collapse: collapse; /* Collapses the borders */
}
.section-how-table-2 {
  width: 100%;
  /* border: 3px solid red; */
  height: 100%;
  font-family: "QuickSand";
  border-collapse: collapse; /* Collapses the borders */
}

.section-how-box {
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  text-align: left; /* Center text in the box */
  width: 40px !important;
  align-items: start;
  align-content: flex-start;
  width: 50%;
}

.section-how-box-2 {
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  text-align: right; /* Center text in the box */
  width: 40px !important;
  align-items: start;
  align-content: flex-start;
  width: 50%;
}

.section-how {
  /* background: url("../../assets/what-page-2.png"); */
  padding: 30px 60px;
  /* Position and center the image to scale nicely on all screens */
  background-position: bottom;
  background-repeat: no-repeat;
  /* background-size: cover; */
  position: relative; /* min-height: 500px; Adjust as needed */
  width: 100%;
}

.box-container {
  left: 0%;
  display: grid;
  grid-template-columns: repeat(
    3,
    calc(100% / 3 - 10px)
  ); /* Adjust width to account for horizontal gap */
  grid-template-rows: repeat(
    3,
    auto
  ); /* Rows will automatically size based on content */
  gap: 15px; /* Horizontal and vertical gap */
}

.box-1 {
  background-color: #7b8e90; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 12px; /* Font size for the text */
  width: 150px;
  border-radius: 10px;
}
.box-2 {
  background-color: #878688; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 12px; /* Font size for the text */
  width: 150px;
  border-radius: 10px;
}
.box-3 {
  background-color: #a6a6a6; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 12px; /* Font size for the text */
  width: 150px;
  border-radius: 10px;
}
.box-4 {
  background-color: #7290a3; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 12px; /* Font size for the text */
  width: 150px;
  border-radius: 10px;
}
.box-5 {
  background-color: #b6afaa; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 12px; /* Font size for the text */
  width: 150px;
  border-radius: 10px;
}

.box-6 {
  background-color: #878688; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 12px; /* Font size for the text */
  width: 150px;
  border-radius: 10px;
}
.box-7 {
  background-color: #a6a6a6; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 12px; /* Font size for the text */
  width: 150px;
  border-radius: 10px;
}
.box-8 {
  background-color: #7b8e90; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 12px; /* Font size for the text */
  width: 150px;
  border-radius: 10px;
}
.box-9 {
  background-color: #7290a3; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 12px; /* Font size for the text */
  width: 150px;
  border-radius: 10px;
}

.icon-why {
  margin: 1rem 0rem;
  color: #878688;
  height: 10px;
  width: 10px;
}

.section-how {
  padding: 20px;
}

.section-how-table {
  width: 100%;
  border-collapse: collapse;
}

.section-how-box {
  width: 50% !important;
  padding: 10px;
}
.section-how-box-2 {
  width: 50% !important;
  padding: 10px;
}

.section-how-box h1 {
  font-size: 22.6px;
  font-weight: 600;
  margin-bottom: 10px;
}
.section-how-box-2 h1 {
  font-size: 22.6px;
  font-weight: bold;
  margin-bottom: 10px;
}
.section-how-box P {
  font-size: 13.7px;
  font-weight: normal;
}
.section-how-box-2 P {
  font-size: 13.7px;
  font-weight: normal;
}

.section-how-box ul {
  padding-left: 20px;
}
.section-how-box-2 ul {
  padding-left: 20px;
}

.section-how-box ul li {
  margin-bottom: 10px;
  list-style-type: disc;
}
.section-how-box-2 ul li {
  margin-bottom: 10px;
  list-style-type: disc;
}

.engage-with-seges {
  margin-bottom: 20px;
}

.engage-with-seges p {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.box-grid-how {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1px;
  width: 50%;
}

.box-how {
  padding: 20px;
  text-align: left;
  font-size: 19.6px;
}

/* .box-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
  } */

/* Base styles (for larger screens) */
.section-how {
  padding: 30px 60px;
  background: url("../../assets/How.png");
  background-position: center;
  background-repeat: no-repeat;
  /* position: relative; */
  background-size: cover;
  width: 100%;
}

.section-how-2 {
  padding: 30px 60px;
  background: url("../../assets/How2.png");
  background-position: center;
  background-repeat: no-repeat;
  /* position: relative; */
  background-size: cover;
  width: 100%;
}

.section-how-table {
  width: 100%;
  border-collapse: collapse;
}
.section-how-2 {
  width: 100%;
  border-collapse: collapse;
}

.section-how-box {
  padding: 20px;
  border-radius: 8px;
  text-align: left;
}
.section-how-box-2 {
  padding: 20px;
  border-radius: 8px;
  text-align: right;
}

.section-how-box h1 {
  font-size: 22.6px;
  font-weight: 600;
  margin-bottom: 10px;
}

.section-how-box-2 h1 {
  font-size: 22.6px;
  font-weight: bold;
  margin-bottom: 10px;
}

.section-how-box p {
  font-size: 13.7px;
}
.section-how-box-2 p {
  font-size: 13.7px;
}

.section-how-box ul {
  padding-left: 20px;
}
.section-how-box-2 ul {
  padding-left: 20px;
}

.section-how-box ul li {
  margin-bottom: 10px;
  list-style-type: disc;
}
.section-how-box-2 ul li {
  margin-bottom: 10px;
  list-style-type: disc;
}

.engage-with-seges {
  margin-bottom: 20px;
}

.engage-with-seges p {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.box-grid-how {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
}

.box-how {
  padding: 20px 20px;
  text-align: left;
  font-size: 19.6px;
}

.icon-why {
  margin: 1rem 0;
  color: #878688;
  height: 20px; /* Increased size for better visibility */
  width: 20px; /* Increased size for better visibility */
}

/* Media queries for mobile screens */
@media (max-width: 768px) {
  .box-grid-how {
    display: inline-block;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1px;
    width: 100%;
  }
  .slick-slider {
    width: 100% !important;
  }
  .section-how {
    padding: 20px;
  }

  .section-how-box {
    width: 100% !important;
    padding: 10px;
  }

  .section-how-box h1 {
    font-size: 18px;
  }

  .section-how-box p {
    font-size: 14px;
  }

  .section-how-box-2 ul {
    padding-left: 15px;
  }

  .section-how-box-2 ul li {
    margin-bottom: 8px;
  }
  /*  */
  .section-how-box-2 {
    width: 100% !important;
    padding: 10px;
  }

  .section-how-box-2 h1 {
    font-size: 18px;
  }

  .section-how-box-2 p {
    font-size: 14px;
  }

  .section-how-box-2 ul {
    padding-left: 15px;
  }

  .section-how-box-2 ul li {
    margin-bottom: 8px;
  }

  .box-grid-how {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 10px;
  }

  .box-how {
    padding: 18px;
    font-size: 16px;
  }

  .icon-why {
    height: 18px;
    width: 18px;
  }
}

@media (max-width: 480px) {
  .box-grid-how {
    display: inline-block;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1px;
    width: 100%;
  }
  .slick-slider {
    width: 100% !important;
  }
  .section-how {
    padding: 10px;
  }

  .section-how-box h1 {
    font-size: 16px;
  }
  .section-how-box {
    display: inline-block;
  }

  .section-how-box p {
    font-size: 12px;
  }

  .section-how-box ul {
    padding-left: 10px;
  }

  .section-how-box ul li {
    margin-bottom: 6px;
  }
  /*  */
  .section-how-box-2 h1 {
    font-size: 16px;
  }
  .section-how-box-2 {
    display: inline-block;
  }

  .section-how-box-2 p {
    font-size: 12px;
  }

  .section-how-box-2 ul {
    padding-left: 10px;
  }

  .section-how-box-2 ul li {
    margin-bottom: 6px;
  }

  .box-grid-how {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 8px;
  }

  .box-how {
    padding: 16px;
    font-size: 14px;
  }

  .icon-why {
    height: 16px;
    width: 16px;
  }
}

/* ICONS */
/* .seges-icons img {
  height: 24px;
  width: 24px;
} */
.box-how img {
  height: 45px !important;
  width: 45px !important;
}

@media (max-width: 768px) {
  .section-how {
    max-width: 100%;
    max-height: max-content;
  }

  .section-how {
    padding: 30px 0px;
    /* background: url("../../assets/How.png"); */
    background: white !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important; /* Subtle shadow for card effect */
    background-position: center !important;
    background-repeat: no-repeat;
    /* position: relative; */
    background-size: contain;
    width: 100%;
  }

  .section-how-2 {
    padding: 30px 0px;
    background-color: white !important;
    /* background: url("../../assets/How2.png"); */
    background: white;
    background-position: center;
    background-repeat: no-repeat;
    /* position: relative; */
    background-size: contain;
    width: 100%;
  }

  .box-how img {
    height: 24px !important;
    width: 24px !important;
  }
  .box-how {
    /* margin: 10px 0px; */
    margin: 5px;
    /* border: 1px solid red; */
    background-color: #fefdffab;
    border-left: 11px solid #000000;
    border-radius: 10px;
    font-weight: bold;
    min-height: fit-content;
    max-height: fit-content;
    width: 230px;
    display: inline-block;
    flex: 1 1 45%; /* Adjust the flex basis to fit columns */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important; /* Subtle shadow for card effect */
    width: 100% !important;
  }
  .box-how-2 {
    width: 100% !important;
    margin: 5px;
    /* border: 1px solid red; */
    background-color: #fefdff1a;
    border-right: 11px solid #000000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important; /* Subtle shadow for card effect */
    border-radius: 10px;
    font-weight: bold;
    min-height: fit-content;
    max-height: fit-content;
    width: 230px;
    display: inline-block;
    flex: 1 1 45%; /* Adjust the flex basis to fit columns */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
  }
}
@media (max-width: 375px) {
  .section-how {
    max-width: 100%;
    max-height: max-content;
  }

  .section-how {
    padding: 30px 0px;
    /* background: url("../../assets/How.png"); */
    background: white !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important; /* Subtle shadow for card effect */
    background-position: center !important;
    background-repeat: no-repeat;
    /* position: relative; */
    background-size: contain;
    width: 100%;
  }

  .section-how-2 {
    padding: 30px 0px;
    background-color: white !important;
    /* background: url("../../assets/How2.png"); */
    background: white;
    background-position: center;
    background-repeat: no-repeat;
    /* position: relative; */
    background-size: contain;
    width: 100%;
  }

  .box-how img {
    height: 24px !important;
    width: 24px !important;
  }
  .box-how {
    /* margin: 10px 0px; */
    margin: 5px;
    /* border: 1px solid red; */
    background-color: #fefdffab;
    border-left: 11px solid #000000;
    border-radius: 10px;
    font-weight: bold;
    min-height: fit-content;
    max-height: fit-content;
    width: 230px;
    display: inline-block;
    flex: 1 1 45%; /* Adjust the flex basis to fit columns */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important; /* Subtle shadow for card effect */
    width: 100% !important;
  }
  .box-how-2 {
    width: 100%;
    margin: 5px;
    /* border: 1px solid red; */
    background-color: #fefdff1a;
    border-right: 11px solid #000000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important; /* Subtle shadow for card effect */
    border-radius: 10px;
    font-weight: bold;
    min-height: fit-content;
    max-height: fit-content;
    width: 230px;
    display: inline-block;
    flex: 1 1 45%; /* Adjust the flex basis to fit columns */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
  }
}
