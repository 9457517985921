/* App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* src/index.css or src/App.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  font-family: "QuickSand" !important;
}

body h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "QuickSand" !important;
}
html {
  scroll-behavior: smooth;
}

.main-content {
  display: flex;
  flex-direction: column;
}

section {
  display: block;
  padding: 50px; /* Adjust padding as needed */
  margin-bottom: 0; /* Space between sections */
  margin-top: 0; /* Space between sections */
  height: max-content !important;
}

/* Optional: Additional styles to ensure full viewport height for sections */
/* section {
  min-height: 100vh; 
} */

/* Responsive Design */
@media (min-width: 375px) and (max-width: 768px) {
  body {
    margin: 0;
    min-width: auto;

    /* font-family: ; */
  }
  html {
    scroll-behavior: smooth;
  }

  .main-content {
    display: flex;
    flex-direction: column;
  }

  section {
    display: block;
    padding: 50px; /* Adjust padding as needed */
    margin-bottom: 0; /* Space between sections */
    margin-top: 2rem; /* Space between sections */
    height: 700px;
  }

  /* Optional: Additional styles to ensure full viewport height for sections */
  /* section {
    min-height: 100vh; 
  } */
}
@media (max-width: 375px) {
  body {
    margin: 0;
    min-width: auto;

    /* font-family: ; */
  }
  html {
    scroll-behavior: smooth;
  }

  .main-content {
    display: flex;
    flex-direction: column;
  }

  section {
    display: block;
    padding: 50px; /* Adjust padding as needed */
    margin-bottom: 0; /* Space between sections */
    margin-top: 0; /* Space between sections */
    height: 700px;
  }

  /* Optional: Additional styles to ensure full viewport height for sections */
  /* section {
    min-height: 100vh; 
  } */
}
