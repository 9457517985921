.section-why-table {
  width: 100%;
  /* border: 3px solid red; */
  height: max-content;
  font-family: "QuickSand";
  border-collapse: collapse; /* Collapses the borders */
}

.box img {
  height: 44px;
  width: 44px;
  background-color: #f5f5f5 !important;
}

.section-why-box {
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  text-align: left; /* Center text in the box */
  width: 40px !important;
  align-items: start;
  align-content: flex-start;
  width: 50%;
}

.section-why {
  background: url("../../assets/section-why.png");
  padding: 30px 60px;
  /* Position and center the image to scale nicely on all screens */
  background-position: bottom;
  background-repeat: no-repeat;
  /* background-size: cover; */
  position: relative; /* min-height: 500px; Adjust as needed */
  background-size: cover;
}

.box-container {
  left: 0%;
  display: grid;
  grid-template-columns: repeat(
    3,
    calc(100% / 3 - 10px)
  ); /* Adjust width to account for horizontal gap */
  grid-template-rows: repeat(
    3,
    auto
  ); /* Rows will automatically size based on content */
  gap: 15px; /* Horizontal and vertical gap */
}

.box-1 {
  background-color: #7b8e90; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 14px !important; /* Font size for the text */
  color: white !important;
  font-weight: bold !important;
  width: 150px;
  border-radius: 10px;
}
.box-2 {
  background-color: #878688; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 14px !important; /* Font size for the text */
  color: white !important;
  font-weight: bold !important;
  width: 150px;
  border-radius: 10px;
}
.box-3 {
  background-color: #a6a6a6; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 14px !important; /* Font size for the text */
  color: white !important;
  font-weight: bold !important;
  width: 150px;
  border-radius: 10px;
}
.box-4 {
  background-color: #7290a3; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 14px !important; /* Font size for the text */
  color: white !important;
  font-weight: bold !important;
  width: 150px;
  border-radius: 10px;
}
.box-5 {
  background-color: #b6afaa; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 14px !important; /* Font size for the text */
  color: white !important;
  font-weight: bold !important;
  width: 150px;
  border-radius: 10px;
}

.box-6 {
  background-color: #878688; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 14px !important; /* Font size for the text */
  color: white !important;
  font-weight: bold !important;
  width: 150px;
  border-radius: 10px;
}
.box-7 {
  background-color: #a6a6a6; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 14px !important; /* Font size for the text */
  color: white !important;
  font-weight: bold !important;
  width: 150px;
  border-radius: 10px;
}
.box-8 {
  background-color: #7b8e90; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 14px !important; /* Font size for the text */
  color: white !important;
  font-weight: bold !important;
  width: 150px;
  border-radius: 10px;
}
.box-9 {
  background-color: #7290a3; /* Background color for the boxes */
  border: 1px solid #ccc; /* Border for the boxes */
  padding: 10px; /* Padding inside the boxes */
  text-align: center; /* Center text inside the boxes */
  font-size: 14px !important; /* Font size for the text */
  color: white !important;
  font-weight: bold !important;
  width: 150px;
  border-radius: 10px;
}

.icon-why {
  margin: 1rem 0rem;
  color: #878688;
  height: 10px;
  width: 10px;
}

.section-why {
  padding: 20px;
}

.section-why-table {
  width: 100%;
  border-collapse: collapse;
}

.section-why-box {
  width: 50% !important;
  padding: 10px;
}

.section-why-box h1 {
  font-size: 22.6px;
  font-weight: 600;
  margin-bottom: 10px;
}
.section-why-box P {
  font-size: 13.7px;
  font-weight: normal;
}

.section-why-box ul {
  padding-left: 20px;
}

.section-why-box ul li {
  margin-bottom: 10px;
  list-style-type: disc;
}

.engage-with-seges {
  margin-bottom: 20px;
}

.engage-with-seges p {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.box-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1px;
}

.box {
  padding: 10px;
  text-align: left;
  font-size: 19.6px;
}

/* .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
} */

/* Base styles (for larger screens) */
.section-why {
  padding: 30px 60px;
  background: url("../../assets/section-why.png");
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
}

.section-why-table {
  width: 100%;
  border-collapse: collapse;
}

.section-why-box {
  padding: 20px;
  border-radius: 8px;
  text-align: left;
}

.section-why-box h1 {
  font-size: 22.6px;
  font-weight: 600;
  margin-bottom: 10px;
}

.section-why-box p {
  font-size: 13.7px;
}

.section-why-box ul {
  padding-left: 20px;
}

.section-why-box ul li {
  margin-bottom: 10px;
  list-style-type: disc;
}

.engage-with-seges {
  margin-bottom: 20px;
}

.engage-with-seges p {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.box-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 14px;
}

.box {
  padding: 10px 50px;
  text-align: left;
  font-size: 19.6px;
}

.icon-why {
  margin: 1rem 0;
  color: #878688;
  height: 20px; /* Increased size for better visibility */
  width: 20px; /* Increased size for better visibility */
}

/* Media queries for mobile screens */
@media (max-width: 768px) {
  .section-why {
    padding: 20px;
    /* border: 1px solid red; */
    /* max-width: 100%; */
    overflow: hidden; /* or hidden, scroll, etc. */
    max-width: 100%;
    height: max-content;
    border: 1px solid black;
  }

  .section-why-box {
    max-width: 400px !important; /* Ensure the element width adapts to content */
    padding: 10px;
    display: inline;
  }

  .section-why-box h1 {
    font-size: 18px;
    text-overflow: clip;
    max-width: max-content;
  }

  .section-why-box p {
    font-size: 14px;
    text-overflow: clip;
    max-width: max-content;
  }

  .section-why-box ul {
    text-wrap: nowrap;
    /* border: 1px solid red; */
    padding-left: 15px !important;
    white-space: inherit; /* Prevent text wrapping */
    max-width: 400px; /* Ensure the element width adapts to content */
  }

  .section-why-box ul li {
    margin-bottom: 8px;
    text-overflow: clip;
    max-width: max-content;
  }

  .box-grid {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 10px;
  }

  .box-container {
    left: 0%;
    display: grid;
    grid-template-columns: repeat(
      2,
      calc(100% / 2 - 10px)
    ); /* Adjust width to account for horizontal gap */
    grid-template-rows: repeat(
      2,
      auto
    ); /* Rows will automatically size based on content */
    gap: 15px; /* Horizontal and vertical gap */
  }

  .box-1 {
    padding: 10px; /* Padding inside the boxes */
    text-align: center; /* Center text inside the boxes */
    font-size: 12px !important; /* Font size for the text */
    width: 60px !important;
    border-radius: 10px !important;
    color: white !important;
    font-weight: 500 !important;
  }
  .box-2 {
    padding: 10px; /* Padding inside the boxes */
    text-align: center; /* Center text inside the boxes */
    font-size: 12px !important; /* Font size for the text */
    width: 60px !important;
    border-radius: 10px !important;
  }
  .box-3 {
    padding: 10px; /* Padding inside the boxes */
    text-align: center; /* Center text inside the boxes */
    font-size: 12px !important; /* Font size for the text */
    width: 60px !important;
    border-radius: 10px !important;
  }
  .box-4 {
    padding: 10px; /* Padding inside the boxes */
    text-align: center; /* Center text inside the boxes */
    font-size: 12px !important; /* Font size for the text */
    width: 60px !important;
    border-radius: 10px !important;
  }
  .box-5 {
    padding: 10px; /* Padding inside the boxes */
    text-align: center; /* Center text inside the boxes */
    font-size: 12px !important; /* Font size for the text */
    width: 60px !important;
    border-radius: 10px !important;
  }
  .box-6 {
    padding: 10px; /* Padding inside the boxes */
    text-align: center; /* Center text inside the boxes */
    font-size: 12px !important; /* Font size for the text */
    width: 60px !important;
    border-radius: 10px !important;
  }
  .box-7 {
    padding: 10px; /* Padding inside the boxes */
    text-align: center; /* Center text inside the boxes */
    font-size: 12px !important; /* Font size for the text */
    width: 60px !important;
    border-radius: 10px !important;
  }
  .box-8 {
    padding: 10px; /* Padding inside the boxes */
    text-align: center; /* Center text inside the boxes */
    font-size: 12px !important; /* Font size for the text */
    width: 60px !important;
    border-radius: 10px !important;
  }
  .box-9 {
    padding: 10px; /* Padding inside the boxes */
    text-align: center; /* Center text inside the boxes */
    font-size: 12px !important; /* Font size for the text */
    width: 60px !important;
    border-radius: 10px !important;
  }

  .box {
    padding: 8px;
    font-size: 16px;
  }

  .icon-why {
    height: 18px;
    width: 18px;
  }
}

@media (max-width: 480px) {
  .section-why {
    padding: 10px;
  }

  .section-why-box {
    width: inherit !important;
    /* border: 1px solid red; */
  }

  .section-why-box h1 {
    font-size: 16px;
  }
  .section-why-box {
    display: block;
  }

  .section-why-box p {
    font-size: 12px;
  }

  .section-why-box ul {
    padding-left: 10px;
  }

  .section-why-box ul li {
    margin-bottom: 6px;
  }

  .box-grid {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 8px;
    display: inline-block;
  }

  .box {
    padding: 6px;
    font-size: 14px;
  }

  .icon-why {
    height: 16px;
    width: 16px;
  }
}

.slider-container {
  overflow: hidden; /* Hide scrollbars */
  position: relative;
}

.slick-slider {
  overflow: hidden; /* Hide scrollbars */
}
