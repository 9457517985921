/* Style for dots */
.slick-dots {
  bottom: 10px !important;
}

.slick-dots li button:before {
  font-size: 10px;
  color: #000 !important; /* Dot color */
}

.slick-dots li.slick-active button:before {
  color: #000 !important; /* Active dot color */
}

/* Style for arrows */
.slick-prev,
.slick-next {
  z-index: 1 !important;
}

.slick-prev {
  left: 10px !important; /* Position from the left */
}

.slick-next {
  right: 10px !important; /* Position from the right */
}

.slick-prev .arrow,
.slick-next .arrow {
  font-size: 12px !important;
}
