.section-where-table-1 {
  width: 100%;
  /* border: 3px solid red; */
  height: 100px;
  font-family: "QuickSand";
  border-collapse: collapse; /* Collapses the borders */
  top: 0%;
  text-align: left;
}
.section-where-table-1 {
  width: 100%;
  border-collapse: collapse;
}

.logo-linkedin svg {
  height: 30px !important;
  width: 30px !important;
}

.section-where-box {
  margin: 1rem;
  align-content: flex-start;
  position: relative;
  width: 41.33%;
}
.section-where-box h1 {
  /* margin-bottom: 0px; */
  font-size: 43.5px;
  margin: 1rem;
}
.section-where-box h3 {
  /* margin-bottom: 0px; */
  font-size: 19.6px;
  margin: 0rem 1rem;
}
.section-where-box h6 {
  /* margin-bottom: 0px; */
  font-size: 15.6px;
  margin: 1rem 1rem;
}

.who-footer p {
  margin: 1rem;
  font-size: 15.6px;
  text-align: center;
  font-weight: 600;
}

.arrow-cell {
  align-items: center;
  /* border: 1px solid red; */
  padding: 1rem;
  transform: rotateX(180deg); /* Vertically flip the arrow */
}

.section-where-table {
  /* background-color: red; */
  /* border: 11px solid black !important; */
  padding: 0px 0px;
  display: flex; /* Use flexbox for centering */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center children vertically */
  align-items: start; /* Center children horizontally */

  align-self: center;
}

.section-where-main {
  /* background: url("../../assets/Who_bg_img.png"); */
  background-repeat: fill;
  background-size: contain;
  position: relative; /* min-height: 500px; Adjust as needed */
}

.section-where h1,
h3 {
  font-weight: bold !important;
}
.section-where-table {
  width: 100%;
}
body {
  font-family: "QuickSand";
}

.section-where-title {
  background: url("../../assets/where_bg.png");
  background-repeat: fill;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: auto;
  width: 100%;
  color: white;
  border-radius: 10px;
  padding: 1rem;
}

.section-where-title h1 {
  padding: 1rem;
  font-size: 22.6px;
  font-weight: bold;
}
.section-where-title p {
  padding: 0.2rem 0.8rem;
  font-size: 15.6px;
  font-weight: bold;
}

.section-where-title h1 {
  font-size: 22.6px;
}
.section-where-description-cell p {
  font-size: 20px;
}

.four-columns td {
  border-left: 1px solid #d5d5d5;
}

.four-columns {
  padding: 1rem !important;
}

.section-where-btn-cell {
  width: 300px;
  height: 230px;
  padding: 1rem;
}
.section-where-btn-cell h1 {
  color: #878688;
  font-size: 40px;
  margin: 0px;
  text-transform: uppercase;
}

.section-where-btn-cell h3 {
  color: black;
  font-size: 20px;
  text-transform: uppercase;
}
.section-where-btn-cell h6 {
  color: black;
  font-size: 15px;
  margin: 0px;
  font-weight: lighter;
}

.section-where-btn-cell td {
  font-size: 19.6px;
}

.section-where-footer-1 {
  border: 1px solid white;
  border-radius: 10px;
  background-color: #878688;
  max-height: 10px;
  margin: 0px;
  padding: 0px;
}

.section-where-footer-1 h1 {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.section-where-footer-2 {
  border: 1px solid white;
}
.section-where-footer-btn {
  width: 20%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: black;
  border: 1px solid white;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}
.section-where-footer-btn:hover {
  background-color: #878688;
}

.section-where-footer-btn-1 {
  background-color: transparent; /* Primary color */
}

.section-where-footer-btn-2 {
  color: black; /* Secondary color */
  float: right;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: 200px;
  margin: 0 10px;
  font-family: "QuickSand";
}

.contact-form input,
.contact-form textarea {
  padding: 0.15rem;
  margin-top: 0.25rem;
  border: 1px solid #8b8b8b;
  background-color: #ffffff80;
  border-radius: 4px;
  font-size: 1rem;
  width: 400px;
}

.contact-form button {
  margin-top: 1rem;
  padding: 0.15rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #0056b3;
}

/* Media queries for mobile screens */
@media (max-width: 768px) {
  .section-where {
    padding: 20px;
    /* max-width: 100%; */
    overflow: auto; /* or hidden, scroll, etc. */
    max-width: max-content;
  }
  .contact-form input,
  .contact-form textarea {
    padding: 0.15rem;
    margin-top: 0.25rem;
    border: 1px solid #8b8b8b;
    background-color: #ffffff80;
    border-radius: 4px;
    font-size: 1rem;
    width: 300px;
  }
}
