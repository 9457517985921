.section-what {
  background: url("../../assets/section-what-bg.png");
  /* border: 11px solid black !important; */
  padding: 50px 60px;
  display: flex; /* Use flexbox for centering */
  flex-direction: column; /* Stack children vertically */
  justify-content: top; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; /* min-height: 500px; Adjust as needed */
  align-self: center;
  min-height: 600px;
}
.Section-What-Main-Container {
  height: max-content !important;
}

.section-what h1,
h3 {
  font-weight: bold !important;
}
.section-what-table {
  width: 100%;
}
body {
  font-family: "QuickSand";
}

.section-what-title-cell h1 {
  margin-bottom: 0px;
  font-size: 25px;
  color: black;
}
.section-what-description {
  margin-bottom: 20px;
}
.section-what-description-cell p {
  font-size: 20px;
  color: black;
}

.four-columns-what td {
  border-left: 1px solid #d5d5d5;
}

.four-columns-what {
  padding: 1rem !important;
}

.section-what-btn-cell {
  /* width: 500px; */
  /* height: 230px; */
  padding: 3rem 2rem;
}
.section-what-btn-cell h1 {
  color: #878688;
  font-size: 40px;
  margin: 0px;
  font-weight: 600 !important;
  text-transform: uppercase;
}

.section-what-btn-cell h3 {
  color: black;
  font-size: 20px;
  font-weight: 500 !important;
  text-transform: uppercase;
}

.section-what-btn-cell h6 {
  color: black;
  font-size: 15px;
  margin: 0px;
  font-weight: lighter;
}

.section-what-btn-cell td {
  font-size: 19.6px;
}

/*  */
.section-what-btn-cell-2 {
  /* width: 500px; */
  /* height: 230px; */
  padding: 3rem 5rem;
}
.section-what-btn-cell-2 h1 {
  color: #878688;
  font-size: 40px;
  margin: 0px;
  font-weight: 600 !important;
  text-transform: uppercase;
}

.section-what-btn-cell-2 h3 {
  color: black;
  font-size: 20px;
  font-weight: 500 !important;
  text-transform: uppercase;
}

.section-what-btn-cell-2 h6 {
  color: black;
  font-size: 15px;
  margin: 0px;
  font-weight: lighter;
  max-width: 200px;
}

.section-what-btn-cell-2 td {
  font-size: 19.6px;
}

/*  */
.section-what-btn-cell-3 {
  /* text-align: left; */
  /* width: 300px !important; */
  /* height: 230px; */
  padding: 3rem 2.5rem;
  /* border: 1px solid red; */
}
.section-what-btn-cell-3 h1 {
  color: #878688;
  font-size: 40px;
  margin: 0px;
  font-weight: 600 !important;
  text-transform: uppercase;
}

.section-what-btn-cell-3 h3 {
  color: black;
  font-size: 20px;
  font-weight: 500 !important;
  text-transform: uppercase;
}

.section-what-btn-cell-3 h6 {
  color: black;
  font-size: 15px;
  margin: 0px;
  font-weight: lighter;
  width: 200px;
}

.section-what-btn-cell-3 td {
  font-size: 19.6px;
}

.section-what-footer-1 {
  border: 1px solid white;
  border-radius: 10px;
  background-color: #878688;
  max-height: 10px;
  margin: 0px;
  padding: 0px;
}

.section-what-footer-1 h1 {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.section-what-footer-2 {
  border: 1px solid white;
}
.section-what-footer-btn {
  width: 20%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: black;
  border: 1px solid white;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}
.section-what-footer-btn:hover {
  background-color: #878688;
}

.section-what-footer-btn-1 {
  background-color: transparent; /* Primary color */
}

.section-what-footer-btn-2 {
  color: black; /* Secondary color */
  float: right;
}

.logo-linkedin-what svg {
  height: 30px;
  width: 30px;
  color: grey !important; /* Customize hover color */
  position: absolute;
  bottom: 20px;
  right: 20px !important;
  background-color: white; /* Customize the background color */
  /* color: black; Customize the icon color */
  color: grey; /* Customize hover color */
  border-radius: 5%;
  padding: 1px;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

/* <-----------------------Section what slide 2--------------------------------------> */

.section-what-2 {
  background: url("../../assets/what-page-2.png");
  /* border: 1px solid black !important; */
  padding: 0px 60px;
  /* Position and center the image to scale nicely on all screens */
  background-position: bottom;
  background-repeat: no-repeat;
  /* background-size: cover; */
  position: relative; /* min-height: 500px; Adjust as needed */
}
.section-what-3 {
  background: url("../../assets/section-what-3.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 660px !important;

  /* Flexbox for Centering */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 20px; /* Optional padding for responsiveness */
}

.section-what-four-columns-2 td {
  border-left: 0px solid red;
}
.slider-container {
  overflow: hidden; /* Hide scrollbars */
  position: relative;
}

.slick-slider {
  overflow: hidden; /* Hide scrollbars */
}

.slide-item {
  transition: transform 0.5s ease-in-out; /* Smooth transition for slides */
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-slide img {
  /* width: 100%;
  height: 100%; */
  transition: transform 0.5s ease-in-out;
}
/* max-width: 768px */

@media (max-width: 768px) {
  section {
    height: max-content !important;
  }
  .section-what-btn-cell-3 {
    /* text-align: left; */
    /* width: 300px !important; */
    /* height: 230px; */
    padding: 15px 20px !important;
    /* border: 1px solid red; */
  }

  .Section-What-Main-Container {
    max-height: max-content !important;
    min-height: max-content !important;
  }

  .section-what {
    background: url("../../assets/section-what-bg-mobile.png") no-repeat bottom; /* Position image at the bottom */
    background-position: center bottom; /* Move the image 20px below */

    width: 100%;
    padding: 10px 15px; /* Adjusted padding for mobile view */
    display: block;
    background-size: 250px; /* Ensure the image covers the area */
    background-repeat: no-repeat;
    /* flex-direction: column; Stack children vertically */
    color: transparent !important;
    max-height: max-content !important;
    min-height: max-content !important;

    /* Flexbox for Centering */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 20px; /* Optional padding for responsiveness */
  }

  .section-what h1,
  h3 {
    font-weight: bold !important;
  }

  .section-what-title-cell h1 {
    margin-bottom: 0px;
    font-size: 20px;
  }
  .section-what-description-cell p {
    font-size: 15px;
    max-width: 300px;
    /* border: 1px solid red; */
  }

  .section-what-2 {
    /* Set up background image */
    background: white no-repeat bottom;
    background-size: 250px; /* Ensure the image covers the area */
    background-position: center bottom -20px; /* Move the image 20px below */
    padding: 0px 40px;
    margin-top: 0rem;
    position: relative; /* Allow positioning of the pseudo-element */
    height: min-content !important;

    /* Add a dark overlay using a pseudo-element */
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: max-content;
      background: rgba(255, 255, 255, 0);
      z-index: 1; /* Ensure the overlay is above the background image */
    }

    /* Ensure content inside .section-what-2 appears above the overlay */
    .content {
      position: relative;
      z-index: 2;
    }
  }

  .section-what-btn-cell-2 h6 {
    color: black;
    font-size: 15px;
    margin: 0px;
    font-weight: lighter;
    max-width: 200px;
    text-align: center;
    max-width: inherit !important;
  }

  /* Specific order adjustment for one td */
  .section-what-four-columns .special-order {
    order: -1 !important;
  }

  .section-what-four-columns-2 {
    display: block;
    flex-wrap: wrap; /* Allow items to wrap in case of overflow */
    gap: 10px; /* Add some gap between columns */
    width: 100%;
    padding: 0% !important;
    margin: 1% 0% !important;
  }

  .section-what-four-columns-2 td:is(:empty) {
    height: 0% !important;
    width: 0% !important;
    border: 1px solid yellow;
  }

  .section-what-btn-cell-2:is(:empty) {
    height: 0% !important;
    width: 0% !important;
    border: 1px solid yellow;
    padding: 0% !important;
  }

  /* Apply styles only to non-empty td elements */
  .section-what-four-columns-2 td:not(:empty) {
    text-align: center;
    margin: 5px;
    /* background-color: #ffffff; White background color for the card */
    border-left: 1px solid black; /* Light border to define card edges */
    /* border-radius: 10px; Rounded corners for the card */
    font-weight: bold; /* Bold text for emphasis */
    padding: 10px; /* Add padding for inner spacing */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Subtle shadow for card effect */
    min-height: fit-content;
    max-height: fit-content;
    width: 300px;
    display: inline-block; /* Make each cell act as a block-level element */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }

  .four-columns-what {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap in case of overflow */
    gap: 10px; /* Add some gap between columns */
    width: 100%;
    padding: 0% !important;
    margin: 1% 0% !important;
  }

  .four-columns-what td {
    margin: 5px;
    /* border: 1px solid red; */
    /* background-color: #fefdff8c; */
    border-left: 1px solid #000000;
    /* border-radius: 10px; */
    font-weight: bold;
    min-height: fit-content;
    max-height: fit-content;
    width: 100%;
    display: block;
    flex: 1 1 45%; /* Adjust the flex basis to fit columns */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
  }

  .slider-container {
    overflow: hidden; /* Hide scrollbars */
    width: 100%; /* Ensure the slider container takes full width */
  }

  .slick-slider {
    overflow: visible; /* Ensure slick-slider is visible */
    width: 100%; /* Ensure slick-slider takes full width */
    /* border: 5px solid red; Just for visualization, adjust or remove */
    padding: 0%;
    margin: 0%;
  }
}

@media (max-width: 375px) {
  .Section-What-Main-Container {
    max-height: max-content;
    min-height: max-content;
  }

  .section-what {
    background: url("../../assets/section-what-bg-mobile.png") no-repeat bottom; /* Position image at the bottom */
    width: 100%;
    padding: 10px 15px; /* Adjusted padding for mobile view */
    display: block;
    background-size: 250px; /* Ensure the image covers the area */
    background-repeat: no-repeat;
    /* flex-direction: column; Stack children vertically */
    color: transparent !important;
    max-height: max-content !important;
    min-height: max-content !important;
  }

  .section-what h1,
  h3 {
    font-weight: bold !important;
  }

  .section-what-title-cell h1 {
    color: black;

    margin-bottom: 0px;
    font-size: 20px;
  }
  .section-what-description-cell p {
    color: black;
    font-size: 15px;
    max-width: 300px;
    /* border: 1px solid red; */
  }

  .section-what-2 {
    /* Set up background image */
    background: white; /* Position image at the bottom-right */
    background-repeat: no-repeat;
    padding: 10px 15px; /* Adjusted padding for mobile view */
    background-size: cover; /* Ensure the image covers the area */
    padding: 0px 40px;
    margin-top: 0rem;
    position: relative; /* Allow positioning of the pseudo-element */
    height: min-content !important;

    /* Add a dark overlay using a pseudo-element */
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: max-content;
      background: rgba(255, 255, 255, 0);
      z-index: 1; /* Ensure the overlay is above the background image */
    }

    /* Ensure content inside .section-what-2 appears above the overlay */
    .content {
      position: relative;
      z-index: 2;
    }
  }

  .section-what-four-columns-2 td {
    /* border-left: 1px solid red; */
  }

  /* Specific order adjustment for one td */
  .section-what-four-columns .special-order {
    order: -1 !important;
  }

  .section-what-four-columns-2 {
    display: block;
    flex-wrap: wrap; /* Allow items to wrap in case of overflow */
    gap: 10px; /* Add some gap between columns */
    width: 100%;
    padding: 0% !important;
    margin: 1% 0% !important;
  }

  .section-what-four-columns-2 td:is(:empty) {
    height: 0% !important;
    width: 0% !important;
    border: 1px solid yellow;
  }
  .section-what-btn-cell:is(:empty) {
    width: 0px;
    height: 0px;
    padding: 0px;
  }

  /* Apply styles only to non-empty td elements */
  .section-what-four-columns-2 td:not(:empty) {
    margin: 5px;
    /* border: 1px solid red; */
    /* background-color: #fefdff8c; */
    border-left: 1px solid #000000;
    /* border-radius: 10px; */
    font-weight: bold;
    min-height: fit-content;
    min-height: fit-content;
    max-height: fit-content;
    width: fit-content;
    display: block;
    flex: 1 1 45%; /* Adjust the flex basis to fit columns */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
  }
  .four-columns-what {
    display: contents;
    flex-wrap: wrap; /* Allow items to wrap in case of overflow */
    gap: 10px; /* Add some gap between columns */
    width: 100%;
    padding: 0% !important;
    margin: 1% 0% !important;
  }

  .four-columns-what td {
    margin: 5px;
    /* border: 1px solid red; */
    /* background-color: #fefdff8c; */
    border-left: 1px solid #000000;
    /* border-radius: 10px; */
    font-weight: bold;
    min-height: fit-content;
    max-height: fit-content;
    width: fit-content;
    display: block;
    flex: 1 1 45%; /* Adjust the flex basis to fit columns */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
  }

  .slider-container {
    overflow: hidden; /* Hide scrollbars */
    width: 100%; /* Ensure the slider container takes full width */
  }

  .slick-slider {
    overflow: visible; /* Ensure slick-slider is visible */
    width: 100%; /* Ensure slick-slider takes full width */
    /* border: 5px solid red; Just for visualization, adjust or remove */
    padding: 0%;
    margin: 0%;
  }
}
/* Style for dots */
.slick-dots {
  bottom: 10px !important;
}

.slick-dots li button:before {
  font-size: 10px;
  color: #000 !important; /* Dot color */
}

.slick-dots li.slick-active button:before {
  color: #000 !important; /* Active dot color */
}

/* Style for arrows */
.slick-prev,
.slick-next {
  z-index: 1 !important;
}

.slick-prev {
  left: 10px !important; /* Position from the left */
}

.slick-next {
  right: 10px !important; /* Position from the right */
}

.slick-prev .arrow,
.slick-next .arrow {
  font-size: 12px !important;
  background: black;
  color: red;
}

.slick-prev:before {
  color: black !important;
}
.slick-next:before {
  color: black !important;
}
