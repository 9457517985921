/* Navbar.scss */

.navbar-container-main {
  position: relative; /* Keep the navbar fixed at the top */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;
  background-color: transparent; /* Transparent by default */
}

/* Shrink and Transparent Effect on Scroll */
.navbar-container-main.navbar-scrolled {
  background-color: rgba(255, 255, 255, 0); /* Light transparent white */
  // backdrop-filter: blur(10px); /* Blur effect */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: all 0.3s ease;
}

/* Shrink Navbar Elements */
.navbar-container-main.navbar-scrolled .navbar-container {
  padding: 5px 20px !important; /* Reduce padding */
  height: 60px !important; /* Shrink navbar height */
  transition: all 0.3s ease;
}

.navbar-container-main.navbar-scrolled .logo-container img {
  height: 60px !important; /* Shrink logo height */
  transform: scale(1.2) !important; /* Slight logo scale-down */
  transition: all 0.3s ease;
}

.navbar-container-main.navbar-scrolled .hamburger-menu,
.navbar-container-main.navbar-scrolled .hamburger-menu-left {
  width: 25px !important;
  height: 25px !important;
  transition: all 0.3s ease;
}

.navbar-container-main.navbar-scrolled .line,
.navbar-container-main.navbar-scrolled .line-left {
  width: 20px !important;
  height: 2px !important;
  transition: all 0.3s ease;
}

.navbar-container-main {
  position: relative; /* Keep the navbar fixed */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensure it's above all content */
  background-color: #fff; /* Default solid background */
  transition: all 0.3s ease;
  box-shadow: none;

  &.navbar-scrolled {
    background-color: transparent !important; /* Fully transparent */
    box-shadow: none; /* No shadow */

    .navbar-container {
      padding: 5px 20px; /* Shrink padding */
    }

    .logo-container img {
      height: 60px; /* Shrink logo height */
      width: auto;
      transform: scale(1.2); /* Adjust logo scale */
      transition: all 0.3s ease;
    }

    .hamburger-menu,
    .hamburger-menu-left {
      width: 25px;
      height: 25px;
      transition: all 0.3s ease;
    }

    .line,
    .line-left {
      width: 20px;
      height: 2px;
      transition: all 0.3s ease;
    }
  }
}

.logo-container img {
  height: 100px;
  width: 100px;
  transform: scale(2);
  transition: all 0.3s ease; // Smooth transition
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 10px;
  background-color: inherit;
  // border-bottom: 1px solid #ddd;
  overflow: hidden; /* Hide anything that exceeds the container */
}

.logo-container {
  flex: 1;
  display: flex;
  justify-content: center; /* Center the logo horizontally */
  // padding-right: ;
}
.logo-container.open {
  flex: 1;
  display: flex;
  justify-content: center; /* Center the logo horizontally */
  margin-right: -14.7% !important;
}

.logo-container.openLeft {
  flex: 1;
  display: flex;
  justify-content: center; /* Center the logo horizontally */
  margin-right: +15.5% !important;
}

.logo-container img {
  height: 100px; /* Original size */
  width: 100px; /* Original size */
  transform: scale(2); /* Scale the logo by 20% */
  transform-origin: center; /* Keep the logo centered */
  padding: 0px;
}

.hamburger-menu {
  display: inline-flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  order: +1;
  margin-right: 3rem;

  .line:nth-child(1) {
    transform: rotate(90deg) translate(+9px, -8px); /* Rotate and move up */

    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 3px 0;
    transition: 0.3s ease;
  }
  .line:nth-child(2) {
    transform: rotate(90deg); /* Just rotate, keep in the middle */

    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 3px 0;
    transition: 0.3s ease;
  }
  .line:nth-child(3) {
    transform: rotate(90deg) translate(-9px, 8px); /* Rotate and move down */
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 3px 0;
    transition: 0.3s ease;
  }
}

.hamburger-menu-left {
  display: inline-flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  order: -1;
  margin-left: 3rem;

  .line-left:nth-child(1) {
    transform: rotate(90deg) translate(+9px, -8px); /* Rotate and move up */

    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 3px 0;
    transition: 0.3s ease;
  }
  .line-left:nth-child(2) {
    transform: rotate(90deg); /* Just rotate, keep in the middle */

    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 3px 0;
    transition: 0.3s ease;
  }
  .line-left:nth-child(3) {
    transform: rotate(90deg) translate(-9px, 8px); /* Rotate and move down */
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 3px 0;
    transition: 0.3s ease;
  }
}

.navbar-links {
  display: none;
  list-style: none;

  li {
    margin: 0 10px;
  }

  a {
    text-decoration: none;
    color: #333;
  }
}

.navbar-links-left {
  display: none;
  list-style: none;
  z-index: 2;
  li {
    margin: 0 15px;
    z-index: 2;
  }
  a {
    text-decoration: none;
    color: #333;
    z-index: 2;
  }
}

.hamburger-menu.open .line:nth-child(1) {
  transform: rotate(180deg); /* Rotate and move up */
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.3s ease;
}

.hamburger-menu.open .line:nth-child(2) {
  transform: rotate(180deg); /* Rotate and move up */
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.3s ease;
}

.hamburger-menu.open .line:nth-child(3) {
  transform: rotate(180deg); /* Rotate and move up */
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.3s ease;
}

// openLeft
.hamburger-menu-left.openLeft .line-left:nth-child(1) {
  transform: rotate(180deg); /* Rotate and move up */
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.3s ease;
}

.hamburger-menu-left.openLeft .line-left:nth-child(2) {
  transform: rotate(180deg); /* Just rotate, keep in the middle */
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.3s ease;
}

.hamburger-menu-left.openLeft .line-left:nth-child(3) {
  transform: rotate(180deg); /* Rotate and move down */
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.3s ease;
}

.navbar-links.open {
  display: contents;
  flex-direction: row;
  position: absolute;
  top: 60px; // Adjust based on logo height
  right: 0;
  background-color: #fff;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-links-left.openLeft {
  display: contents;
  flex-direction: row;
  position: absolute;
  top: 60px; /* Adjust based on logo height */
  left: 0;
  background-color: #fff;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 768px) {
  .logo-container img {
    height: 100px; /* Original size */
    width: 100px; /* Original size */
    transform: scale(2); /* Scale the logo by 20% */
    transform-origin: center; /* Keep the logo centered */
    padding: 0px;
  }
  .logo-container.open {
    flex: 1;
    display: flex;
    justify-content: center; /* Center the logo horizontally */
    margin-left: -18% !important;
  }
  .logo-container.openLeft {
    flex: 1;
    display: flex;
    justify-content: center; /* Center the logo horizontally */
    margin-left: -16% !important;
  }
  .navbar-links {
    display: none;
  }

  .hamburger-menu {
    display: flex;
  }
  .hamburger-menu-left {
    display: flex;
  }

  .navbar-links.open {
    display: flex;
    margin-top: 25px;
  }
  .navbar-links-left.openLeft {
    display: flex;
    margin-top: 25px;
  }
}
@media (max-width: 480px) {
  .logo-container img {
    height: 100px; /* Original size */
    width: 100px; /* Original size */
    transform: scale(1.75); /* Scale the logo by 20% */
    transform-origin: center; /* Keep the logo centered */
    padding: 0px;
  }
  .logo-container.open {
    flex: 1;
    display: flex;
    justify-content: center; /* Center the logo horizontally */
    margin-left: -14% !important;
  }

  .logo-container.openLeft {
    flex: 1;
    display: flex;
    justify-content: center; /* Center the logo horizontally */
    margin-left: 0% !important;
  }

  .navbar-links {
    display: none;
  }

  .hamburger-menu {
    display: flex;
    margin-right: 0rem !important;
  }
  .hamburger-menu-left {
    display: flex;
    margin-left: 0rem !important;
    margin-top: 0rem !important;
  }

  .navbar-links.open {
    display: flex;
    margin-top: 25px;
    left: 45% !important;
  }
  .navbar-links-left.openLeft {
    display: inline-flex;
    color: black !important;
    margin-top: 25px !important;
  }
  .navbar-links li {
    color: black !important;
    display: inline-flex;
    margin-top: 3rem !important;
  }
  .navbar-links-left li {
    color: black !important;
    display: inline-flex;
    margin-top: 3rem !important;
  }
}

@media (max-width: 476px) {
  .mobile-menu {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 16px 20px;
    transition: transform 0.3s ease-in-out;
    transform: translateY(-100%);
  }

  .mobile-menu.open {
    transform: translateY(0);
  }

  .mobile-menu a {
    display: block;
    font-size: 18px;
    font-weight: 600;
    color: #333;
    padding: 14px 0;
    border-bottom: 1px solid #ddd;
    text-align: center;
    transition: color 0.3s;
  }

  .mobile-menu a:last-child {
    border-bottom: none;
  }

  .mobile-menu a:hover {
    color: #f59e0b;
  }

  .menu-toggle {
    font-size: 24px;
    padding: 12px;
    background: none;
    border: none;
    cursor: pointer;
  }

  .menu-toggle:focus {
    outline: none;
  }
}
