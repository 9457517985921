.discover-solution-btn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 100;
  border: 1px solid white;
  border-radius: 25px;
}

.discover-button {
  background-color: transparent;
  color: yellow;
  font-weight: bold;
  padding: 12px 16px;
  border: none;
  border-radius: 25px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.discover-button .icon {
  transition: transform 0.3s ease;
}

.discover-button:hover {
  background-color: yellow;
  color: white;
  transform: scale(1.05);
}

.discover-button:hover .icon {
  transform: rotate(10deg);
}
